<template>
  <div class="container-fluid py-4 add-student grade-detail paid-remain">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">
                    تقرير المسدد والمتبقي
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="mb-3 col-12 tab-div tab2">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li style="width: 33%" class="nav-item" role="presentation">
                  <button
                    class="w-96 nav-link btn mb-0 bg-gradient-info btn-md null null btn-lg"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    تقرير المدرس
                  </button>
                </li>
                <li style="width: 33%" class="nav-item" role="presentation">
                  <button
                   class="w-96 nav-link btn mb-0 bg-gradient-info btn-md null null btn-lg"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    تقرير الدورة
                  </button>
                </li>
              </ul>
            </div>
            <!-- exceptional attendance row -->
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <CreateCall
                  v-model="showModal2"
                  @hide-modal="showModal2 = false"
                  :studentInfo="studentInfo"
                  :calls="calls"
                ></CreateCall>
                <div class="row align-center ex-row">
                     <div class="mb-3 col-lg-4">
                  <label>
                    السنة الدراسية
                    <span color="danger" class="error">{{
                      errors.academic_year_id ? errors.academic_year_id[0] : ''
                    }}</span>
                  </label>
                  <div class="input-group input-group-outline null">
                    <select
                      class="form-control"
                      v-model="selectedYear"
                      @change ="selectedSemester = ''"
                      required
                    >
                      <option selected value="">اختر السنة الدراسية</option>
                      <option
                        v-for="year in academicYear"
                        :key="year.id"
                        :value="year.id"
                      >
                        {{ year.name }}
                      </option>
                    </select>
                    <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
                      <div class="mb-3 col-lg-4">
                    <div>
                  <label>
                    الترم الدراسي
                    <span color="danger" class="error">{{
                      errors.semester_id ? errors.semester_id[0] : ''
                    }}</span>
                  </label>
                  </div><br>
                  <div class="term-con">
                  <div class="input-group input-group-outline null">
                     <div
                    class="form-check"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      value="1"
                      v-model="selectedSemester"
                      @change="getSubjects()"
                    />
                    <label class="form-check-label">
                     الأول
                    </label>
                  </div>
                  </div>
                   <div class="input-group input-group-outline null">
                     <div
                    class="form-check"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      value="2"
                      v-model="selectedSemester"
                       @change="getSubjects()"
                    />
                    <label class="form-check-label" >
                     الثاني
                    </label>
                  </div>
                  </div>
                  </div>
                </div>
                  <div class="mb-3 col-lg-4">
                    <label> المدرس <span class="red">*</span></label>
                    <multiselect
                      class="required form-control"
                      id="grade"
                      v-model="selectedInstructor"
                      :options="instructors"
                      :searchable="true"
                      :close-on-select="true"
                      placeholder=" اختر المدرس"
                      @select="getSubjects($event)"
                      label="name"
                      :custom-label="customLabel"
                      track-by="id"
                      required="required"
                      :internal-search="true"
                    >
                      >
                      <template v-slot:noOptions>
                        <span> فارغة </span>
                      </template>
                      <template v-slot:noResult>
                        <span>لا يوجد نتيجة </span>
                      </template>
                    </multiselect>
                  </div>
                  <div class="mb-3 col-lg-4">
                    <label>المادة<span class="red">*</span></label>
                    <multiselect
                      class="required form-control"
                      id="student"
                      v-model="selectedSubject"
                      :options="subjects"
                      :searchable="true"
                      :close-on-select="true"
                      placeholder=" اختر المادة"
                      label="name"
                      :custom-label="customLabel"
                      track-by="id"
                      required="required"
                      :internal-search="true"
                    >
                      >
                      <template v-slot:noOptions>
                        <span> فارغة </span>
                      </template>
                      <template v-slot:noResult>
                        <span>لا يوجد نتيجة </span>
                      </template>
                    </multiselect>
                  </div>
                  <div class="mb-3 col-lg-4">
                    <label> نوع الدورة</label>
                    <div class="input-group input-group-outline null">
                      <select class="form-control" v-model="selectedType">
                        <option selected value="">الكل</option>
                        <option class="val" value="1">أونلاين</option>
                        <option value="0">أوفلاين</option>
                      </select>
                      <i class="fas fa-caret-down" aria-hidden="true"></i>
                    </div>
                    </div>
                            <div class="mb-3 col-lg-4">
                         <label> رقم الصفحة</label>
                        <vmd-input
                        id="student-number"
                        type="text"
                        placeholder="ادخل رقم الصفحة  "
                        v-model="pageNumber"
                            />
                          </div>
                  <div class="text-center col-lg-2">
                    <vmd-button
                      class="my-4 mb-2 width action-btn"
                      variant="gradient"
                      color="primary"
                      @click="filterData('instructor')"
                    >
                      فلتر</vmd-button
                    >
                  </div>
                  <div class="text-center col-lg-2">
                    <vmd-button
                      class="my-4 mb-2 width action-btn"
                      variant="gradient"
                      color="info"
                      @click="printReport('instructor')"
                    >
                      طباعة</vmd-button
                    >
                  </div>
                  <div class="text-center col-lg-2">
                    <vmd-button
                      class="my-4 mb-2 width action-btn"
                      variant="gradient"
                      color="warning"
                      @click="downloadFile('instructor')"
                    >
                      اكسل</vmd-button
                    >
                  </div>
                </div>
                <div
                  id="print-report"
                  style="margin-top: 40px; direction: rtl !important"
                >
                  <div
                    class="row times"
                    style="margin-top: 40px; direction: rtl !important"
                  >
                    <div class="col-md-4">
                      <h5>
                        المدرس:
                        <span class="green">{{
                          selectedInstructor ? selectedInstructor.name : null
                        }}</span>
                      </h5>
                    </div>
                    <div class="col-md-4">
                      <h5>
                        المادة:
                        <span class="green">{{
                          selectedSubject ? selectedSubject.name : null
                        }}</span>
                      </h5>
                    </div>
                    <div class="col-md-4">
                      <h5>
                        الفرقة: <span class="green">{{ grade }}</span>
                      </h5>
                    </div>
                  </div>
                  <div
                    class="row align-center"
                    style="margin-top: 40px; direction: rtl !important"
                  >
                    <div
                      class="text-center col-lg-12 overflow-x"
                      style="direction: rtl"
                    >
                      <table class="b-block table" style="direction: rtl">
                        <thead>
                          <tr>
                            <th rowspan="2">#</th>
                            <th rowspan="2">رقم الطالب</th>
                            <th rowspan="2">اسم الطالب</th>
                            <th rowspan="2">الهاتف</th>
                            <th colspan="3">المكالمات</th>
                            <th rowspan="2">المجموعة</th>
                            <th rowspan="2">رقم الدورة</th>
                            <th rowspan="2">نوع الدورة</th>
                            <th rowspan="2">تكلفة الدورة</th>
                            <th rowspan="2">الحضور</th>
                            <th rowspan="2">المسدد</th>
                            <th rowspan="2">المتبقي</th>
                          </tr>
                          <tr>
                            <th>إضافة</th>
                            <th>اخر مكالمة</th>
                            <th>المكالمة القادمة</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(student, index) in students"
                            :key="student.id"
                          >
                            <td>{{ index + 1 }}</td>
                            <td>{{ student.number }}</td>
                            <td>{{ student.full_name }}</td>
                            <td>
                              <a
                                class="to-link"
                                :href="`tel:${student.mobile}`"
                              >
                                {{ student.mobile }}</a
                              >
                            </td>
                            <td>
                              <button
                                style="width: unset !important"
                                @click="
                                  getCallDetails(
                                    'instructor',
                                    student.full_name,
                                    student.id,
                                    student.calls
                                  )
                                "
                                class="badge btn btn-info badge-sm pointer"
                              >
                                إضافة
                              </button>
                            </td>
                            <td>
                              {{
                                student.last_call
                                  ? student.last_call.created_at
                                  : null
                              }}
                            </td>
                            <td>
                              {{
                                student.last_call
                                  ? student.last_call.next_call_date
                                  : null
                              }}
                            </td>
                            <td>{{ student.group_name }}</td>
                            <td>{{ student.course_number }}</td>
                            <td>
                              {{
                                student.course_type === 1
                                  ? "أونلاين"
                                  : "أوفلاين"
                              }}
                            </td>
                            <td>{{ $filters.number(student.course_cost) }}</td>
                            <td class="text-center">
                              {{ student.attend }}
                            </td>
                            <td>{{ $filters.number(student.payed) }}</td>
                            <td>{{ $filters.number(student.remain) }}</td>
                          </tr>
                          <tr style="background: #dad6d669; font-weight: bold">
                            <td colspan="11">الإجمالي</td>
                            <td>{{ instrCourseInfo.totalAttendance }}</td>
                            <td>
                              {{ $filters.number(instrCourseInfo.totalPaid) }}
                            </td>
                            <td>
                              {{ $filters.number(instrCourseInfo.totalRemain) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                        <!-- pagination -->
           <div class="row mt-3">
            <div class=" col-lg-9 col-sm-12 overflow-x">
  <nav aria-label="...">
    <ul class="pagination pagination-lg">
      <li @click="paginate('prev')" class="pointer" :class="{'pagi-active': currentPage === 1}">
        <span class="page-link pagi-item"><i class="fas fa-chevron-right"></i></span>
      </li>
      <li @click="paginate(page)" class="pointer" :class="{'pagi-active': currentPage === page}" v-for="page in visiblePages" :key="page">
        <span class="page-link pagi-item">{{ page }}</span>
      </li>
        <li v-if="showEllipsisAfter" class="pointer">
          <span class="page-link pagi-item">...</span>
        </li>
      <li @click="paginate('next')" class="pointer" :class="{'pagi-active': currentPage === allPages}">
        <span class="page-link pagi-item"><i class="fas fa-chevron-left"></i></span>
      </li>
    </ul>
  </nav>
  </div>
  <div class=" col-lg-3  text-center col col-sm-12" v-if="students?.length">
    <div class="">
      يعرض <span class="total-tex green">{{currentPage}}</span> من   <span class="total-tex green">{{allPages}} </span> صفحة
    </div>
  </div>
</div>
                  </div>
                </div>
                <!-- end -->
              </div>
              <!-- courses  -->
              <div
                class="tab-pane fade show"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <CreateCall
                  v-model="showModal"
                  @hide-modal="showModal = false"
                  :studentInfo="studentInfo"
                  :calls="calls"
                ></CreateCall>
                <div class="row align-center ex-row">
                  <span class="filter-text mb-2"
                    >اختر السنة الدراسية ثم الترم للدورة المناسبة</span
                  >
                   <div class="mb-3 col-lg-4">
                  <label>
                    السنة الدراسية
                    <span color="danger" class="error">{{
                      errors.academic_year_id ? errors.academic_year_id[0] : ''
                    }}</span>
                  </label>
                  <div class="input-group input-group-outline null">
                    <select
                      class="form-control"
                      v-model="selectedYear"
                      @change ="selectedSemester = ''"
                      required
                    >
                      <option selected value="">اختر السنة الدراسية</option>
                      <option
                        v-for="year in academicYear"
                        :key="year.id"
                        :value="year.id"
                      >
                        {{ year.name }}
                      </option>
                    </select>
                    <i class="fas fa-caret-down" aria-hidden="true"></i>
                  </div>
                </div>
                   <div class="mb-3 col-lg-4">
                    <div>
                  <label>
                    الترم الدراسي
                    <span color="danger" class="error">{{
                      errors.semester_id ? errors.semester_id[0] : ''
                    }}</span>
                  </label>
                  </div><br>
                  <div class="term-con">
                  <div class="input-group input-group-outline null">
                     <div
                    class="form-check"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      value="1"
                      v-model="selectedSemester"
                      @change="getCourses"
                    />
                    <label class="form-check-label">
                     الأول
                    </label>
                  </div>
                  </div>
                   <div class="input-group input-group-outline null">
                     <div
                    class="form-check"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      value="2"
                      v-model="selectedSemester"
                       @change="getCourses"
                    />
                    <label class="form-check-label" >
                     الثاني
                    </label>
                  </div>
                  </div>
                  </div>
                </div>
                  <div class="mb-3 col-lg-4">
                    <label> الدورة <span class="red">*</span></label>
                    <multiselect
                      class="required form-control"
                      id="grade"
                      v-model="selectedCourse"
                      :options="courses"
                      :searchable="true"
                      :close-on-select="true"
                      placeholder=" اختر الدورة"
                      @select="getStudents($event)"
                      label="name"
                      :custom-label="customLabel"
                      track-by="id"
                      required="required"
                      :internal-search="true"
                    >
                      >
                      <template v-slot:noOptions>
                        <span> فارغة </span>
                      </template>
                      <template v-slot:noResult>
                        <span>لا يوجد نتيجة </span>
                      </template>
                    </multiselect>
                  </div>
                  <div class="mb-3 col-lg-4">
                    <label>الطلاب</label>
                    <multiselect
                      class="required form-control"
                      id="student"
                      v-model="selectedStudent"
                      :options="studentsList"
                      :searchable="true"
                      :close-on-select="true"
                      placeholder=" اختر الطالب"
                      label="name"
                      :custom-label="customLabel"
                      track-by="id"
                      required="required"
                      :internal-search="true"
                    >
                      >
                      <template v-slot:noOptions>
                        <span> فارغة </span>
                      </template>
                      <template v-slot:noResult>
                        <span>لا يوجد نتيجة </span>
                      </template>
                    </multiselect>
                  </div>
                  <div class="text-center col-lg-2">
                    <vmd-button
                      class="my-4 mb-2 width action-btn"
                      variant="gradient"
                      color="primary"
                      @click="filterData('course')"
                    >
                      فلتر</vmd-button
                    >
                  </div>
                  <div class="text-center col-lg-2">
                    <vmd-button
                      class="my-4 mb-2 width action-btn"
                      variant="gradient"
                      color="info"
                      @click="printReport('course')"
                    >
                      طباعة</vmd-button
                    >
                  </div>
                  <div class="text-center col-lg-2">
                    <vmd-button
                      class="my-4 mb-2 width action-btn"
                      variant="gradient"
                      color="warning"
                      @click="downloadFile('course')"
                    >
                      اكسل</vmd-button
                    >
                  </div>
                </div>
                <div
                  id="print-report2"
                  style="margin-top: 40px; direction: rtl !important"
                >
                  <div
                    class="row times"
                    style="margin-top: 40px; direction: rtl !important"
                  >
                    <div class="col-md-8">
                      <h5>
                        الدورة: <span class="green">{{ selectedCourse.name }}</span>
                      </h5>
                    </div>
                  </div>
                  <div
                    class="row align-center"
                    style="margin-top: 40px; direction: rtl !important"
                  >
                    <div
                      class="text-center col-lg-12 overflow-x"
                      style="direction: rtl"
                    >
                      <table class="b-block table" style="direction: rtl">
                        <thead>
                          <tr>
                            <th rowspan="2">#</th>
                            <th rowspan="2">رقم الطالب</th>
                            <th rowspan="2">اسم الطالب</th>
                            <th rowspan="2">الهاتف</th>
                            <th colspan="3">المكالمات</th>
                            <th rowspan="2">المجموعة</th>
                            <th rowspan="2">رقم الدورة</th>
                            <th rowspan="2">تكلفة الدورة</th>
                            <th rowspan="2">الحضور</th>
                            <th rowspan="2">المسدد</th>
                            <th rowspan="2">المتبقي</th>
                          </tr>
                          <tr>
                            <th>إضافة</th>
                            <th>اخر مكالمة</th>
                            <th>المكالمة القادمة</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(student, index) in courseStudents"
                            :key="student.id"
                          >
                            <td>{{ index + 1 }}</td>
                            <td>{{ student.number }}</td>
                            <td>
                              <router-link
                                class="to-link pointer"
                                :to="{
                                  path: '/students/' + student.id + '/show',
                                }"
                                >{{ student.full_name }}</router-link
                              >
                            </td>
                            <td>
                              <a
                                class="to-link"
                                :href="`tel:${student.mobile}`"
                              >
                                {{ student.mobile }}</a
                              >
                            </td>
                            <td>
                              <button
                                style="width: unset !important"
                                @click="
                                  getCallDetails(
                                    'courses',
                                    student.full_name,
                                    student.id,
                                    student.calls
                                  )
                                "
                                class="badge btn btn-info badge-sm pointer"
                              >
                                إضافة
                              </button>
                            </td>
                            <td>
                              {{
                                student.last_call
                                  ? student.last_call.created_at
                                  : null
                              }}
                            </td>
                            <td>
                              {{
                                student.last_call
                                  ? student.last_call.next_call_date
                                  : null
                              }}
                            </td>
                            <td>
                              {{ student.group ? student.group.name : null }}
                            </td>
                            <td>{{ student.course_number }}</td>
                            <td>{{ $filters.number(student.course_cost) }}</td>
                            <td class="text-center">
                              {{ student.attend }}
                            </td>
                            <td>{{ $filters.number(student.paid) }}</td>
                            <td>{{ $filters.number(student.remain) }}</td>
                          </tr>
                          <tr style="background: #dad6d669; font-weight: bold">
                            <td colspan="9">الإجمالي</td>
                            <td>
                              {{
                                $filters.number(courseTotalInfo.coursesTotal)
                              }}
                            </td>
                            <td></td>
                            <td>
                              {{ $filters.number(courseTotalInfo.totalPaid) }}
                            </td>
                            <td>
                              {{ $filters.number(courseTotalInfo.totalRemain) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!-- end  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import VmdInput from '@/components/VmdInput.vue'
import CreateCall from '@/components/CreateCall.vue'
import exportFromJSON from 'export-from-json'
import swal from 'sweetalert'
import $ from 'jquery'
const date = new Date()
const today =
  date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
export default {
  name: 'PaiedRemainingReport',
  components: {
    VmdButton,
    CreateCall,
    VmdInput
  },
  data () {
    return {
      tableDate: today,
      instructors: [],
      selectedInstructor: '',
      subjects: [],
      selectedSubject: '',
      students: [],
      studentsFile: [],
      courseStudents: [],
      courses: [],
      selectedCourse: '',
      studentsList: [],
      selectedStudent: '',
      instrCourseInfo: {},
      courseTotalInfo: {},
      grade: '',
      course: '',
      selectedType: '',
      count: 0,
      showModal: false,
      showModal2: false,
      studentInfo: '',
      calls: [],
      selectedSemester: '',
      academicYear: [],
      selectedYear: '',
      // pagination-info
      page: 1,
      currentPage: 1,
      allPages: 0,
      pageNumber: 1,
      visiblePages: [],
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}instructors`).then((response) => {
      this.instructors = response.data.data
    })
    this.$http.get(`${this.$hostUrl}subjects`).then((response) => {
      this.subjects = response.data.data
    })
    this.$http.get(`${this.$hostUrl}courses`).then((response) => {
      $.each(response.data.data, (key, val) => {
        this.courses.push({
          id: val.id,
          name: val.number + '-' + val.name
        })
      })
    })
    const academicYear = []
    this.$http.get(`${this.$hostUrl}academic-years`).then((response) => {
      $.each(response.data.data, function (key, value) {
        academicYear.push({
          name: value.name,
          id: value.id
        })
      })
      this.academicYear = academicYear
    })
    this.$http.get(`${this.$hostUrl}settings`).then((response) => {
      this.selectedYear = response.data.data.academic_year_id
      this.selectedSemester = response.data.data.semester
    })
  },
  computed: {
    showEllipsisAfter () {
      return this.visiblePages[this.visiblePages.length - 1] < this.allPages
    }
  },
  methods: {
    getCallDetails (report, studentName, studentId, calls) {
      this.studentInfo = {
        name: studentName,
        id: studentId
      }
      this.$http
        .get(`${this.$hostUrl}calls?student_id=${studentId}`)
        .then((response) => {
          this.calls = response.data.data
        })
      report === 'instructor'
        ? (this.showModal2 = true)
        : (this.showModal = true)
    },
    getSubjects ($event) {
      this.selectedSubject = ''
      if ($event && $event.id) {
        this.selectedInstructor = $event
      } else {
        // Use this.selectedInstructor.id if $event.id is not available
        this.selectedInstructor = this.selectedInstructor || {}
      }
      this.$http
        .get(`${this.$hostUrl}subjects?instructor_id=${this.selectedInstructor.id}
        &semester=${this.selectedSemester}`)
        .then((response) => {
          this.subjects = response.data.data
        })
    },
    getStudents ($event) {
      this.selectedStudent = ''
      this.$http
        .get(`${this.$hostUrl}courses/${$event.id}`)
        .then((response) => {
          // this.students = response.data.data
          const students = []
          $.each(response.data.data.students, (key, val) => {
            students.push({
              name: val.full_name,
              id: val.id
            })
          })

          this.studentsList = students
        })
    },
    getCourses () {
      this.courses = []
      this.selectedCourse = ''
      this.$http
        .get(`${this.$hostUrl}courses?semester=${this.selectedSemester}&academic_year_id=${this.selectedYear}`)
        .then((response) => {
          this.courses = response.data.data
        })
    },
    // pagin start
    filterInsData () {
      if (!this.selectedInstructor || !this.selectedSubject) {
        swal('يجب ادخال كل البيانات')

        return
      }

      const page = this.pageNumber !== '' ? parseInt(this.pageNumber, 10) : 1
      this.fetchInsData(this.selectedInstructor.id, this.selectedSubject.id, page)
    },

    paginate (action) {
      if (!this.selectedInstructor) {
        return
      }
      let page = this.currentPage

      if (action === 'prev') {
        page = Math.max(1, page - 1) // Decrement page number, but keep it within bounds
      } else if (action === 'next') {
        page = Math.min(this.allPages, page + 1) // Increment page number, but keep it within bounds
      } else {
        page = parseInt(action, 10) // Go to the specified page
      }
      this.pageNumber = page.toString()
      this.fetchInsData(this.selectedInstructor.id, this.selectedSubject.id, page)
    },

    fetchInsData (InstructorId, subjectId, page) {
      const url = `${this.$hostUrl}instructor-payment-report?instructor_id=${InstructorId}&subject_id=${subjectId}&type=${this.selectedType}&semester=${this.selectedSemester}&academic_year_id=${this.selectedYear}&page=${page}`

      this.$http.get(url)
        .then(response => {
          if (!response.data.data.students.data.length) {
            swal('لا توجد بيانات')
          }
          this.students = response.data.data.students.data
          this.grade = response.data.data.subject.grade.name
          this.instrCourseInfo = {
            totalPaid: response.data.data.total_payed,
            totalRemain: response.data.data.total_remain,
            totalAttendance: 0
          }
          $.each(this.students, (key, val) => {
            this.instrCourseInfo.totalAttendance += val.attend
          })
          this.currentPage = response.data.data.students.current_page
          this.allPages = response.data.data.students.last_page
          // this.totalStudents = response.data.data.total
          const totalVisiblePages = 10 // Adjust the number of visible pages as per your requirement
          const startPage = Math.max(1, this.currentPage - Math.floor(totalVisiblePages / 2))
          const endPage = Math.min(this.allPages, startPage + totalVisiblePages - 1)
          this.visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)
        })
    },
    // pagin end
    filterData (data) {
      switch (data) {
        case 'instructor':
          this.filterInsData()
          break
        case 'course':
          if (!this.selectedCourse) {
            swal('يجب ادخال  الدورة')
          } else {
            this.$http
              .get(
                `${this.$hostUrl}course-payment-report?course_id=${
                  this.selectedCourse.id
                }&student_id=${
                  this.selectedStudent ? this.selectedStudent.id : ''
                }&semester=${this.selectedSemester}&academic_year_id=${this.selectedYear}`
              )
              .then((response) => {
                if (!response.data.data.students.length) {
                  swal('لا توجد بيانات')
                }
                this.courseStudents = response.data.data.students
                this.course = response.data.data.course
                console.log(
                  this.courseStudents.length,
                  response.data.data.course_cost
                )
                this.courseTotalInfo = {
                  coursesTotal: 0,
                  totalPaid: 0,
                  totalRemain: 0
                }
                $.each(this.courseStudents, (key, val) => {
                  this.courseTotalInfo.coursesTotal += val.course_cost
                  this.courseTotalInfo.totalPaid += val.paid
                  this.courseTotalInfo.totalRemain += val.remain
                })
              })
          }
      }
    },
    downloadFile (report) {
      if (report === 'instructor') {
        this.$http
          .get(`${this.$hostUrl}report/export/instructor-payment-report?instructor_id=${this.selectedInstructor.id}&subject_id=${this.selectedSubject.id}&type=${this.selectedType}&semester=${this.selectedSemester}&academic_year_id=${this.selectedYear}`)
          .then(response => {
            const excelStudents = response.data.data.students
            $.each(excelStudents, (key, val) => {
              this.studentsFile.push({
                رقم: val.number,
                الاسم: val.full_name,
                الهاتف: val.mobile,
                المجموعة: val.group_name,
                تكلفة_الدورة: val.course_cost,
                الحضور: val.attend,
                المسدد: val.payed,
                المتبقي: val.remain
              })
            })
            const data = this.studentsFile
            const fileName = ` تقرير المسدد والمتبقي للمدرس : ${this.selectedInstructor.name}- ${today}`
            const exportType = exportFromJSON.types.xls
            if (data) exportFromJSON({ data, fileName, exportType })
          })
      }

      if (report === 'course') {
        $.each(this.courseStudents, (key, val) => {
          this.studentsFile.push({
            مسلسل: key + 1,
            رقم: val.number,
            الاسم: val.full_name,
            الهاتف: val.mobile,
            المجموعة: val.group_name,
            تكلفة_الدورة: val.course_cost,
            الحضور: val.attend,
            المسدد: val.paid,
            المتبقي: val.remain
          })
        })
        const data = this.studentsFile
        const fileName = ` تقرير المسدد والمتبقي دورة : ${this.course}  - ${today}`
        const exportType = exportFromJSON.types.xls
        if (data) exportFromJSON({ data, fileName, exportType })
      }
    },
    printReport (data) {
      $('.card-footer').hide()
      const options = {
        name: ' Seven Eleven System ',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css',
          'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css',
          `${window.location.origin}/print-report.css`
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: 'Seven Eleven System' // override the window title
      }
      if (data === 'instructor') {
        setTimeout(this.$htmlToPaper('print-report', options), 10000)
        $('.card-footer').show()
      } else {
        setTimeout(this.$htmlToPaper('print-report2', options), 10000)
        $('.card-footer').show()
      }
    }
  }
}
</script>
<style>
#collection-date {
  font-weight: 800 !important;
  font-size: 18px !important;
}
.table > :not(:first-child) {
  border-bottom: 1px solid #f0f2f5 !important;
}
.width {
  width: 100%;
}
.online {
  border: 1px solid #ddd;
}
.times {
  margin-top: 10px !important;
}
.times h5 {
  font-size: 16px !important;
}
.times .red {
  position: relative !important;
}
.width {
  width: 100% !important;
}
</style>
